import {
  CategoriesDisplayOptions,
  MultiOfferingsDisplayOptions,
} from '../display-options/offering-list-widget-display-options';
import {
  AlignmentOptions,
  OfferingListLayoutOptions,
} from '../../Shared/appKeys/SettingsKeys';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { VARIANT } from 'wix-ui-tpa/dist/src/components/Tabs/constants';
import { Experiments } from '../../Shared/experiments/experiments';

export interface IMultiOfferingsViewModel {
  title: TitleViewModel;
  layout: string;
  categoriesViewModel: CategoriesViewModel;
  maxItemsPerRow: number;
  spaceBetweenOfferings: number;
  withDivider: boolean;
  dividerWidth: number;
  cardMinWidth: number;
  cardMaxWidth: number;
  rtl?: boolean; //todo - should be removed when yoshi will fix rtl!!!
  uniformRowHeight: boolean;
}

export interface TitleViewModel {
  text: string;
  isVisible: boolean;
  alignment: AlignmentOptions;
}

export interface CategoriesViewModel {
  isVisible: boolean;
  alignment: AlignmentOptions;
  variant: string;
  categories: OfferingCategoryDto[];
}

export enum ListLayoutOptions {
  COLUMN = 'COLUMN',
  GRID = 'GRID',
}

export const CARD_MAX_WIDTH = 616;

function getTitle(title, formatter: Function) {
  return title || formatter('multi-offerings.title');
}

//todo - should be removed when yoshi will fix rtl!!!
function getAlignment(isRTL, alignment) {
  return !isRTL || alignment === AlignmentOptions.CENTER
    ? alignment
    : alignment === AlignmentOptions.LEFT
    ? AlignmentOptions.RIGHT
    : AlignmentOptions.LEFT;
}

function getUniformRowHeight(isMobile) {
  return !isMobile;
}

function getSpacingBetweenOfferings(
  layout,
  spacing,
  stripSpacing,
  cardsSpacing,
) {
  return layout === OfferingListLayoutOptions.GRID
    ? cardsSpacing
    : layout === OfferingListLayoutOptions.STRIP
    ? stripSpacing
    : spacing;
}

function getCategoriesWithTranslations(categories, formatter) {
  return categories.map(category => ({
    ...category,
    name: formatter(category.name),
  }));
}

export class MultiOfferingsViewModelFactory {
  static createMultiOfferingsViewModel(
    multiOfferingsDisplayOptions: MultiOfferingsDisplayOptions,
    categories: OfferingCategoryDto[],
    formatter: Function,
    rtl: boolean, //todo - should be removed when yoshi will fix rtl!!!
    isMobile: boolean,
    isDummyMode: boolean,
    experiments: Experiments = {},
  ): IMultiOfferingsViewModel {
    const titleView = {
      text: getTitle(multiOfferingsDisplayOptions.title, formatter),
      isVisible: multiOfferingsDisplayOptions.isTitleVisible,
      alignment: getAlignment(rtl, multiOfferingsDisplayOptions.titleAlignment), //todo - should be removed when yoshi will fix rtl!!!
    };

    const categoriesDisplayOptions: CategoriesDisplayOptions =
      multiOfferingsDisplayOptions.categoriesDisplayOptions;
    const categoriesViewModel: CategoriesViewModel = {
      isVisible:
        categoriesDisplayOptions.isCategoriesVisible &&
        categories &&
        categories.length > 1,
      alignment: getAlignment(
        rtl,
        categoriesDisplayOptions.categoriesContentAlignment,
      ), //todo - should be removed when yoshi will fix rtl!!!
      variant: categoriesDisplayOptions.isCategoriesContentStretch
        ? VARIANT.fullWidth
        : VARIANT.fit,
      categories: isDummyMode
        ? getCategoriesWithTranslations(categories, formatter)
        : categories,
    };

    const offeringListLayout = ListLayoutOptions.COLUMN;

    const dividerWidth =
      isMobile ||
      multiOfferingsDisplayOptions.layout !== OfferingListLayoutOptions.STRIP
        ? 0
        : multiOfferingsDisplayOptions.dividerWidth;

    const maxItemsPerRow =
      isMobile ||
      multiOfferingsDisplayOptions.layout !== OfferingListLayoutOptions.GRID
        ? 1
        : multiOfferingsDisplayOptions.maxItemsPerRow;
    const cardMinWidth = isMobile
      ? 130
      : multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.GRID
      ? 240
      : 700;
    const withDivider =
      !isMobile &&
      multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.STRIP &&
      multiOfferingsDisplayOptions.isDividerVisible;
    const spaceBetweenOfferings = isMobile
      ? multiOfferingsDisplayOptions.cardsSpacing
      : getSpacingBetweenOfferings(
          multiOfferingsDisplayOptions.layout,
          multiOfferingsDisplayOptions.spaceBetweenOfferings,
          multiOfferingsDisplayOptions.stripSpaceBetweenOfferings,
          multiOfferingsDisplayOptions.cardsSpacing,
        );
    const uniformRowHeight = getUniformRowHeight(isMobile);
    const cardMaxWidth =
      multiOfferingsDisplayOptions.layout === OfferingListLayoutOptions.GRID &&
      !isMobile
        ? CARD_MAX_WIDTH
        : null;

    return {
      title: titleView,
      layout: offeringListLayout,
      categoriesViewModel,
      dividerWidth,
      maxItemsPerRow,
      spaceBetweenOfferings,
      cardMinWidth,
      cardMaxWidth,
      withDivider,
      uniformRowHeight,
    };
  }
}
